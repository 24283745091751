<template>
  <v-row class="py-2 mx-0">
    <div>
      <v-checkbox
        color="teal lighten-2"
        class="mt-10 ml-3"
        :input-value="selected.includes(item.id)"
        :disabled="disabledView"
        @change="select"
      />
    </div>
    <v-col
      :id="block_id"
      v-click-outside="triggerOnFocus"
      :class="{ 'blocked-box': disabledView }"
    >
      <v-row>
        <v-col
          v-show="isSelectedField(fields.description.value)"
          cols="6"
        >
          <label>{{ fields.description.text }}</label>
          <v-text-field
            v-model="item.description"
            outlined
            dense
            hide-details
          />
        </v-col>

        <v-col
          v-show="isSelectedField(fields.sortiment.value)"
          cols="6"
        >
          <label>{{ fields.sortiment.text }}</label>
          <v-text-field
            v-model="item.sortiment"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col
          v-show="isSelectedField(fields.standard.value)"
          cols="3"
        >
          <label>{{ fields.standard.text }}</label>
          <v-text-field
            v-model="item.standard"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col
          v-show="isSelectedField(fields.type.value)"
          cols="3"
        >
          <label>{{ fields.type.text }}</label>
          <v-text-field
            v-model="item.type"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col
          v-show="isSelectedField(fields.sort.value)"
          cols="6"
        >
          <label>{{ fields.sort.text }}</label>
          <v-text-field
            v-model="item.sort"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="6">
          Величина припуска
          <v-row>
            <v-col
              v-show="isSelectedField(fields.allowance_length.value)"
              cols="4"
            >
              <label>{{ fields.allowance_length.text }}</label>
              <input-numeric
                v-model="item.allowance_length"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col
              v-show="isSelectedField(fields.allowance_width.value)"
              cols="4"
            >
              <label>{{ fields.allowance_width.text }}</label>
              <input-numeric
                v-model="item.allowance_width"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col
              v-show="isSelectedField(fields.allowance_height.value)"
              cols="4"
            >
              <label>{{ fields.allowance_height.text }}</label>
              <input-numeric
                v-model="item.allowance_height"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          Величина отклонений
          <v-row>
            <v-col
              v-show="isSelectedField(fields.deviation_length.value)"
              cols="4"
            >
              <label>{{ fields.deviation_length.text }}</label>
              <input-numeric
                v-model="item.deviation_length"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col
              v-show="isSelectedField(fields.deviation_width.value)"
              cols="4"
            >
              <label>{{ fields.deviation_width.text }}</label>
              <input-numeric
                v-model="item.deviation_width"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col
              v-show="isSelectedField(fields.deviation_height.value)"
              cols="4"
            >
              <label>{{ fields.deviation_height.text }}</label>
              <input-numeric
                v-model="item.deviation_height"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6">
          Диапазон диаметров
          <v-row>
            <v-col
              v-show="isSelectedField(fields.minimal_diameter.value)"
              cols="3"
            >
              <label>{{ fields.minimal_diameter.text }}</label>
              <input-numeric
                v-model="item.minimal_diameter"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col
              v-show="isSelectedField(fields.maximal_diameter.value)"
              cols="3"
            >
              <label>{{ fields.maximal_diameter.text }}</label>
              <input-numeric
                v-model="item.maximal_diameter"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col
              v-show="isSelectedField(fields.minimal_diameter_2.value)"
              cols="3"
            >
              <label>{{ fields.minimal_diameter_2.text }}</label>
              <input-numeric
                v-model="item.minimal_diameter_2"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col
              v-show="isSelectedField(fields.maximal_diameter_2.value)"
              cols="3"
            >
              <label>{{ fields.maximal_diameter_2.text }}</label>
              <input-numeric
                v-model="item.maximal_diameter_2"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          Объем
          <v-row>
            <v-col
              v-show="isSelectedField(fields.contract_volume.value)"
              cols="3"
            >
              <label>{{ fields.contract_volume.text }}</label>
              <input-numeric
                v-model="item.contract_volume"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col
              v-show="isSelectedField(fields.fact_volume.value)"
              cols="3"
            >
              <label>{{ fields.fact_volume.text }}</label>
              <input-numeric
                v-model="item.fact_volume"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col
              v-show="isSelectedField(fields.ware_quantity.value)"
              cols="2"
            >
              <label>{{ fields.ware_quantity.text }}</label>
              <input-numeric
                v-model="item.ware_quantity"
                :fraction="6"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col
              v-show="isSelectedField(fields.invoice_cost.value)"
              cols="2"
            >
              <label>{{ fields.invoice_cost.text }}</label>
              <input-numeric
                v-model="item.invoice_cost"
                :fraction="2"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col
              v-show="isSelectedField(fields.price.value)"
              cols="2"
            >
              <label>{{ fields.price.text }}</label>
              <input-numeric
                value=""
                disabled
                :fraction="2"
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <div class="actions">
      <v-btn
        text
        min-width="40px"
        width="40px"
        @click="search"
      >
        <v-icon> mdi-magnify </v-icon>
      </v-btn>
      <v-btn
        :disabled="disabledView"
        text
        min-width="40px"
        width="40px"
        @click="removeWoodsDetail"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
      <v-btn
        v-if="woods.length - 1 === index"
        :disabled="disabledView"
        text
        min-width="40px"
        width="40px"
        @click="addWoodsDetail"
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </div>
  </v-row>
</template>
<script>
import blockAutoUpdateMixin from "@/mixins/block-auto-update.mixin";
import { readyToUpdateInjector } from "@/helpers/injectors";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import InputNumeric from "@/components/ui/input-numeric.vue";
import { googleImage } from "@/helpers/text";
import { mapGetters } from "vuex";
import {
  FIELDS,
  preparedFields,
} from "@/components/kdt/goods/ware-details/fields";
import { eventBus } from "@/bus";
import { UPDATE_WARE_DETAIL } from "@/components/kdt/goods/ware-details/events";

export default {
  components: { InputNumeric },
  mixins: [blockAutoUpdateMixin, filterBySearchFieldMixin],
  provide() {
    return {
      [readyToUpdateInjector]: this.readyToUpdate,
    };
  },
  props: {
    wood: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    woods: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      block_id: null,
      item: {
        id: null,
        ware_id: null,
        description: null,
        sortiment: null,
        standard: null,
        type: null,
        sort: null,
        allowance_length: null,
        allowance_width: null,
        allowance_height: null,
        deviation_length: null,
        deviation_width: null,
        deviation_height: null,
        minimal_diameter: null,
        maximal_diameter: null,
        minimal_diameter_2: null,
        maximal_diameter_2: null,
        contract_volume: null,
        fact_volume: null,
        sort_index: null,
        ware_quantity: null,
        invoice_cost: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedFields: "kdtSettings/getWareDetailsFields",
      disabledView: "kdt/getVisibility",
    }),
    fields() {
      return preparedFields(FIELDS.woods);
    },
  },
  watch: {
    transport: {
      handler() {
        this.setFields();
        this.setBlockId();
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    this.saveOnScroll();
  },
  methods: {
    saveOnScroll() {
      if (this.hasChanges && this.fields_been_visited)
        this.uploadData()
          .then(() => this.$success("Обновлено"))
          .catch(() => this.$error());
    },
    isSelectedField(field) {
      if (!this.selectedFields.woods.length) return true;
      return this.selectedFields.woods.includes(field);
    },
    getSearchString() {
      const prepared = this.item.pick([
        "description",
        "sortiment",
        "standard",
        "type",
        "sort",
      ]);
      return Object.values(prepared).join(" ");
    },
    search() {
      googleImage(this.getSearchString());
    },
    select() {
      this.$emit("select", this.item.id);
    },
    readyToUpdate() {
      this.fields_been_visited = true;
      this.hasChanges = true;
    },
    setBlockId() {
      this.block_id = `kdt-woods-detail-${this.index}`;
    },
    setFields() {
      Object.keys(this.item).forEach((key) => {
        if (key in this.wood) {
          this.item[key] = this.wood[key];
        }
      });
    },
    addWoodsDetail() {
      this.$emit("add", this.wood.sort_index);
    },
    removeWoodsDetail() {
      this.$emit("remove", { id: this.wood.id, index: this.index });
    },
    uploadData() {
      return this.$store
        .dispatch("kdt/updateWoodDetails", this.item)
        .then((res) => {
          const [item] = res.data;
          eventBus.$emit(UPDATE_WARE_DETAIL, { item, index: this.index });
          return res;
        });
    },
  },
};
</script>
<style scoped>
.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0;
  padding: 0 10px;
  border-left: 1px dotted grey;
  width: 50px;
}
</style>
