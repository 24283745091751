import { mapGetters } from "vuex";

export default {
  data() {
    return {
      virtualScrollItemHeight: 150,
    };
  },
  watch: {
    selectedFields: {
      handler() {
        this.updateVirtualScrollItemHeight();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      selectedFields: "kdtSettings/getWareDetailsFields",
    }),
    virtualScrollHeight() {
      const innerHeight = window.innerHeight;
      const itemsHeight = this.items.length * this.virtualScrollItemHeight;
      return this.items.length
        ? itemsHeight > innerHeight
          ? innerHeight
          : itemsHeight
        : 0;
    },
  },
  methods: {
    updateVirtualScrollItemHeight() {
      this.$nextTick(() => {
        const [firstElement] = this.$refs.virtualScroll?.$children ?? [];
        if (firstElement) {
          this.virtualScrollItemHeight =
            firstElement.$el.getBoundingClientRect()?.height ?? 150;
        }
      });
    },
  },
};
