<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="elevation-0"
        min-width="40px"
        width="40px"
        v-on="on"
        @click="showOnlyField"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </template>
    <span>Только заполненные поля</span>
  </v-tooltip>
</template>
<script>
import {
  FIELDS,
  REQUIRED_WARE_FIELDS,
  REQUIRED_WOODS_FIELDS,
  WARE_TYPE,
  WOODS_TYPE,
} from "@/components/kdt/goods/ware-details/fields";

export default {
  props: {
    type: {
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      itemsLocal: [],
    };
  },
  watch: {
    items: {
      handler(items) {
        this.itemsLocal = items;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    showOnlyField() {
      const filledFields = this.itemsLocal.reduce(
        (previousValue, currentValue) => {
          const currentFields = Object.keys(currentValue).filter(
            (key) => currentValue[key]
          );
          return previousValue.concat(currentFields);
        },
        []
      );
      const uniqueFields = [...new Set(filledFields)];
      const typeFields = Object.keys(FIELDS[this.type]);
      let value = uniqueFields.filter((field) => typeFields.includes(field));
      if (this.type === WARE_TYPE) {
        value = [...new Set(value.concat(REQUIRED_WARE_FIELDS))];
      } else if (this.type === WOODS_TYPE) {
        value = [...new Set(value.concat(REQUIRED_WOODS_FIELDS))];
      }
      this.$store.commit("kdtSettings/SET_WARE_DETAILS_FIELDS", {
        type: this.type,
        value,
      });
    },
  },
};
</script>
