<template>
  <v-col
    class="py-0"
  >
    <v-select
      color="teal"
      item-color="teal"
      class="font-weight-regular"
      outlined
      dense
      hide-details
      item-value="value"
      item-text="text"
      :value="value"
      :items="items"
      :item-disabled="isDisabled"
      label="Отображаемые поля"
      multiple
      @change="updateVisibleFields"
    />
  </v-col>
</template>
<script>
import {mapGetters} from "vuex";
import {
  FIELDS,
  REQUIRED_WARE_FIELDS,
  REQUIRED_WOODS_FIELDS,
  WARE_TYPE,
  WOODS_TYPE
} from "@/components/kdt/goods/ware-details/fields";

export default {
  props: {
    type: {
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      selectedFields: 'kdtSettings/getWareDetailsFields'
    }),
    value() {
      return this.selectedFields[this.type] ?? []
    },
    items() {
      const dictionary = FIELDS[this.type] ?? {}
      return Object.entries(dictionary).map(([value, text]) => ({text, value}))
    }
  },
  created() {
    this.updateVisibleFields()
  },
  methods: {
    isDisabled({value}) {
      if (this.type === WARE_TYPE) {
        return REQUIRED_WARE_FIELDS.includes(value)
      } else if (this.type === WOODS_TYPE) {
        return REQUIRED_WOODS_FIELDS.includes(value)
      }
      return false
    },
    updateVisibleFields(fields) {
      let value = fields ?? []
      if (this.type === WARE_TYPE) {
        value = [...new Set(value.concat(REQUIRED_WARE_FIELDS))]
      } else if (this.type === WOODS_TYPE) (
        value = [...new Set(value.concat(REQUIRED_WOODS_FIELDS))]
      )
      this.$store.commit('kdtSettings/SET_WARE_DETAILS_FIELDS', {type: this.type, value})
    }
  }
}
</script>
